import { Paper, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AppsDonutChart from "./AppsDonutChart";

const mdmUrl = process.env.REACT_APP_BASE_URL

const AppCount = () =>{
    const [loading, setLoading] = useState(false)
    const [activeApps, setActiveApps] = useState(0)
    const [inActiveApps, setInactiveApps] = useState(0)
  
    const { X_Authorization } = useSelector((state: any) => state.users)
  
    const getApps = async () => {
      setLoading(true)
      try {
        const res = await fetch(`${mdmUrl}/api/swipe/app/apps?size=50&start=0`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${X_Authorization}`,
          },
        })
        const data = await res.json()
        console.log(data)
        if (data.status === 0) {
          let fetchedApps = data.data
          let active: any = []
          let inActive: any = []
          fetchedApps.map((app: any) => {
            if (app.status === 1) {
              active.push(app)
            } else if (app.status === 3) {
              inActive.push(app)
            }
          })
          setActiveApps(active.length)
          setInactiveApps(inActive.length)
          setLoading(false)
          console.log(data)
        } else {
          setLoading(false)
        }
      } catch (err) {
        setLoading(false)
        console.error(err)
      }
    }
  
    useEffect(() => {
      getApps()
    }, [])

    return(
        <Stack mt={2} style={{ height:'100%'}}>
        <Paper elevation={2} style={{height:'100%'}}>
          <Stack p={2}>
            <Typography>Number of apps</Typography>
            <Stack className='mt-4 grid place-items-center px-2'>
              <AppsDonutChart active={activeApps} inActive={inActiveApps}/>
            </Stack>
          </Stack>
        </Paper>
      </Stack>
    )
}

export default AppCount;
