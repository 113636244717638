import React, { useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import { PacmanLoader } from 'react-spinners'
import { useDispatch, useSelector } from 'react-redux'
import { Paper, Stack, Typography } from '@mui/material'
import DatePicker from './DatePicker'
import DatePicker2 from './DatePicker2'
import { setShouldReload } from '../features/slices/reloadSlice'
import { useNavigate } from 'react-router-dom'
import SearchableSelect5 from './SearchableSelect5'
import SearchableSelect6 from './SearchableSelect6'
import SearchableSelect7 from './SearchableSelect7'
import SearchableSelect4 from './SearchableSelect4'
const mdmUrl = process.env.REACT_APP_BASE_URL
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'

interface FormDataTypes {
  name: string
  appId: string
  groupId: string
  start: Date | any
  end: Date | any
  description: string
  installType: string
}

const AddTaskModal = (): JSX.Element => {
  const reduxStart: any = useSelector((state: any) => state.dates.start)
  const reduxEnd: any = useSelector((state: any) => state.dates.end)

  const [formData, setFormData] = useState<FormDataTypes>({
    name: '',
    appId: '',
    groupId: '',
    start: '',
    end: '',
    description: '',
    installType: '',
  })
  const [loading, setLoading] = useState<boolean>(false)
  const [apps, setApps] = useState<any>([])
  const [terminalGroups, setTerminalGroups] = useState<any>([])
  const [installTypes, setInstallTypes] = useState<any>([])

  useEffect(() => {
    formData.start = reduxStart
  }, [reduxStart])

  useEffect(() => {
    formData.end = reduxEnd
  }, [reduxEnd])

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = e.target
    setFormData((prevData: any) => ({ ...prevData, [name]: value }))
  }

  const { X_Authorization } = useSelector((state: any) => state.users)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // FETCH APPS
  useEffect(() => {
    const getApps = async () => {
      try {
        const res = await fetch(mdmUrl + '/api/swipe/app/apps?size=50&start=0', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${X_Authorization}`,
          },
        })
        const data = await res.json()
        console.log(data.data)
        setApps(data.data)
      } catch (err) {
        console.error(err)
      }
    }
    getApps()
  }, [])

  // FETCH INSTALL TYPES
  useEffect(() => {
    const getInstallTypes = async () => {
      try {
        const res = await fetch(mdmUrl + '/api/swipe/app/install-types', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${X_Authorization}`,
          },
        })
        const data = await res.json()
        console.log(data.data)
        setInstallTypes(data.data)
      } catch (err) {
        console.error(err)
      }
    }
    getInstallTypes()
  }, [])

  // FETCH TERMINAL GROUP
  useEffect(() => {
    const getTerminalGroups = async () => {
      try {
        const res = await fetch(mdmUrl + '/api/swipe/group/groups?size=50&start=0', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${X_Authorization}`,
          },
        })
        const data = await res.json()
        console.log(data.data)
        setTerminalGroups(data.data)
      } catch (err) {
        console.error(err)
      }
    }
    getTerminalGroups()
  }, [])

  const handleAddTask = async () => {
    setLoading(true)
    try {
      const response = await fetch(mdmUrl + '/api/swipe/group/task/add', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${X_Authorization}`,
        },
        body: JSON.stringify(formData),
      })
      const data = await response.json()
      console.log(data)
      if (data.status === 0) {
        setLoading(false)
        toast.success(`${data.message}`, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'user-toast',
        })
        setFormData({
          name: '',
          appId: '',
          groupId: '',
          start: '',
          end: '',
          description: '',
          installType: '',
        })
        dispatch(setShouldReload())
        navigate('/dashboard/app-store/app-distribution')
      } else {
        setLoading(false)
        toast.error(`${data.error}`, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'addTask-toast',
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Stack width={'100%'} spacing={{xs:5, sm:10, md:30, lg:50}} direction={'row'}>
      <ArrowBackIosNewIcon
        sx={{
          color: 'black',
          fontSize: '2rem',
          '&:hover': { cursor: 'pointer' },
        }}
        onClick={() => {
          window.history.back()
        }}
      />
      <Paper elevation={10}>
        <Stack alignItems={'center'} width={{xs:'350px',sm:'500px'}} p={3}>
          <ToastContainer />
          <Stack spacing={4} width={'100%'} mb={3}>
            <form>
              <Typography variant='h4' color='blue-gray' fontWeight={600}>
                Add Task
              </Typography>
              <div className='flex flex-col mt-2'>
                <Typography variant='h6'>Name</Typography>
                <input
                  type='text'
                  onChange={handleChange}
                  name='name'
                  value={formData.name}
                  className='outline-none w-full rounded-md border-0 py-3 px-2 mt-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                />
              </div>
              <div className='flex flex-col mt-2'>
                <Typography variant='h6'>Description</Typography>
                <input
                  type='text'
                  onChange={handleChange}
                  name='description'
                  value={formData.description}
                  className='outline-none w-full rounded-md border-0 py-3 px-2 mt-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                />
              </div>
              <div className='flex flex-col mt-2'>
                <Typography variant='h6'>Install Type</Typography>
                <SearchableSelect5
                  types={installTypes}
                  handleChange={handleChange}
                />
              </div>
              <div className='flex flex-col mt-2'>
                <Typography variant='h6'>Select App</Typography>
                <SearchableSelect6 apps={apps} handleChange={handleChange} />
              </div>
              <div className='flex flex-col mt-2'>
                <Typography variant='h6'>Select Terminal Group</Typography>
                <SearchableSelect7
                  groups={terminalGroups}
                  handleChange={handleChange}
                />
              </div>
              <div className='flex items-center justify-between mt-2'>
                <Typography variant='h6'>Date</Typography>
              </div>
              <DatePicker />
              <DatePicker2 />
            </form>
          </Stack>
          <Stack width={'80%'}>
            <button
              onClick={() => {
                if (
                  formData.description.length > 0 &&
                  formData.name.length > 0 &&
                  formData.groupId.length > 0 &&
                  formData.appId.length > 0 &&
                  formData.start.length > 0 &&
                  formData.end.length > 0
                ) {
                  handleAddTask()
                } else {
                  toast.error(`Please fill in the fields`, {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'login-toast',
                    theme: 'colored',
                  })
                }
              }}
              className='outline-none flex w-full justify-center rounded-md bg-black px-3 py-3 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-black-2 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
            >
              {loading ? <PacmanLoader color='#fff' /> : 'Save'}
            </button>
          </Stack>
        </Stack>
      </Paper>
    </Stack>
  )
}

export default AddTaskModal
