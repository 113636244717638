import { Paper, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import UsersDonutChart from "./UsersDonutChart";

const mdmUrl = process.env.REACT_APP_BASE_URL

const UsersCount = () =>{
    const [loading, setLoading] = useState(false)
    const [activeUsers, setActiveUsers] = useState(0)
    const [inActiveUsers, setInactiveUsers] = useState(0)
  
    const { X_Authorization } = useSelector((state: any) => state.users)
  
    const getUsers = async () => {
      setLoading(true)
      try {
        const res = await fetch(`${mdmUrl}/api/swipe/user/users?size=50&start=0`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${X_Authorization}`,
          },
        })
        const data = await res.json()
        console.log(data)
        if (data.status === 0) {
          let fetchedUsers = data.data
          let active: any = []
          let inActive: any = []
          fetchedUsers.map((user: any) => {
            if (user.status === 1) {
              active.push(user)
            } else if (user.status === -1) {
              inActive.push(user)
            }
          })
          setActiveUsers(active.length)
          setInactiveUsers(inActive.length)
          setLoading(false)
          console.log(data)
        } else {
          setLoading(false)
        }
      } catch (err) {
        setLoading(false)
        console.error(err)
      }
    }
  
    useEffect(() => {
      getUsers()
    }, [])

    return(
        <Stack mt={2} style={{ height:'100%'}}>
        <Paper elevation={2} style={{height:'100%'}}>
          <Stack p={2}>
            <Typography>Number of users</Typography>
            <Stack className='mt-4 grid place-items-center px-2'>
              <UsersDonutChart active={activeUsers} inActive={inActiveUsers}/>
            </Stack>
          </Stack>
        </Paper>
      </Stack>
    )
}

export default UsersCount;
