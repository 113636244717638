import { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PacmanLoader, SyncLoader } from 'react-spinners';
import { setToken, setIsLoggedIn, setUserRole } from '../../features/slices/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Stack } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';

const mdmUrl = process.env.REACT_APP_BASE_URL;

const Login = (): JSX.Element => {
  const navigate = useNavigate();
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [captcha, setCaptcha] = useState<string>('');
  const [captchaId, setCaptchaId] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [captchaSource, setCaptchaSource] = useState<string>('');
  const [loadingCaptcha, setLoadingCaptcha] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  
  const dispatch = useDispatch();
  const { isLoggedIn, X_Authorization } = useSelector((store: any) => store.users);

  useEffect(() => {
    if (isLoggedIn) navigate('/dashboard');
  }, [isLoggedIn]);

  useEffect(() => {
    getCaptcha();
  }, [reload]);

  // Function to handle reloading captcha
  const handleReload = () => {
    setReload(!reload);
    setCaptcha(''); // Reset captcha input field
  };

  // Function to fetch and display captcha
  const getCaptcha = async () => {
    setLoadingCaptcha(true);
    try {
      const res = await fetch(`${mdmUrl}/api/captcha/generate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Authorization: `Bearer ${X_Authorization}`,
        },
      });
      const data = await res.json();
      if (data.code === 1) {
        setCaptchaSource(data.data);
        setCaptchaId(data.captchaId);
      } else {
        toast.error('Failed to load captcha');
      }
    } catch (err) {
      console.error('Error fetching captcha:', err);
      toast.error('Error loading captcha');
    } finally {
      setLoadingCaptcha(false);
    }
  };

  // Separate function for form validation
  const validateForm = () => {
    return username.trim() !== '' && password.trim() !== '' && captcha.trim() !== '';
  };

  // Function to verify captcha before login
  const verifyCaptcha = async () => {
    const response = await fetch(`${mdmUrl}/api/captcha/verify`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Id: captchaId,
        VerifyValue: captcha,
      }),
    });
    const data = await response.json();
    return data.code === 1; // Return true if captcha is verified
  };

  // Function to handle user login
  const handleLogin = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setSubmitted(true);
    
    if (!validateForm()) {
      toast.error('Please fill in all fields', {
        position: toast.POSITION.TOP_RIGHT,
        className: 'login-toast',
        theme: 'colored',
      });
      return;
    }

    setLoading(true);
    try {
      const isCaptchaValid = await verifyCaptcha();
      if (!isCaptchaValid) {
        toast.error('Incorrect captcha value. Please try again.');
        handleReload();
        setLoading(false);
        return;
      }

      // Perform the login if captcha is valid
      const response = await fetch(`${mdmUrl}/api/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password, captchaId, captchaValue: captcha }),
      });
      const data = await response.json();
      
      if (response.status === 200) {
        dispatch(setIsLoggedIn(true));
        // dispatch(setUserRole('admin'));
        dispatch(setToken(data.token));
        navigate('/dashboard');
      } else {
        toast.error(`${data.error}`, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'login-toast',
          theme: 'colored',
        });
      }
    } catch (err) {
      console.error('Login error:', err);
      toast.error('Login failed. Please try again.', {
        position: toast.POSITION.TOP_RIGHT,
        className: 'login-toast',
        theme: 'colored',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {!isLoggedIn && (
        <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
          <ToastContainer />
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <Stack width={'100%'} alignItems={'center'} my={2}>
              <NavLink
                to=""
                className="bg-gradient-to-r p-5 rounded-full w-20 h-20 flex justify-center items-center from-black from-50% via-sky-500 via-20% to-emerald-500 to-100%"
              >
                <h1 className="text-white font-bold">MDM</h1>
              </NavLink>
            </Stack>
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Sign in to your account
            </h2>
          </div>
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm shadow-2xl p-10">
            <form className="space-y-6" onSubmit={handleLogin}>
              {/* Username Input */}
              <div>
                <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                  Username
                </label>
                <div className="mt-2">
                  <input
                    id="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    type="text"
                    autoComplete="username"
                    className={`block w-full rounded-md py-3 px-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 outline-none shadow-sm placeholder:text-gray-400 focus:ring-red-500 focus:ring-2 sm:text-sm sm:leading-6
                    ${submitted && username.trim() === '' ? 'border-red-500' : 'border-0'}`}
                  />
                </div>
              </div>

              {/* Password Input */}
              <div>
                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                  Password
                </label>
                <div className="mt-2">
                  <input
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    autoComplete="current-password"
                    className={`block w-full rounded-md py-3 px-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 outline-none shadow-sm placeholder:text-gray-400 focus:ring-red-500 focus:ring-2 sm:text-sm sm:leading-6
                    ${submitted && password.trim() === '' ? 'border-red-500' : 'border-0'}`}
                  />
                </div>
              </div>

              {/* Captcha */}
              <Stack mt={2} width={'100%'} height={'100px'} alignItems={'center'} justifyContent={'center'} p={2}>
                {loadingCaptcha ? (
                  <SyncLoader color="#121212" margin={4} />
                ) : (
                  <img alt="captcha" src={captchaSource} />
                )}
              </Stack>

              <Stack direction={'row'} spacing={3}>
                <label htmlFor="captcha" className="block text-sm font-medium leading-6 text-gray-900">
                  Captcha
                </label>
                <CachedIcon onClick={handleReload} sx={{cursor: 'pointer'}} />
              </Stack>
              <div className="mt-2">
                <input
                  id="captcha"
                  value={captcha}
                  onChange={(e) => setCaptcha(e.target.value)}
                  type="text"
                  className={`block w-full rounded-md py-3 px-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 outline-none shadow-sm placeholder:text-gray-400 focus:ring-red-500 focus:ring-2 sm:text-sm sm:leading-6
                  ${submitted && captcha.trim() === '' ? 'border-red-500' : 'border-0'}`}
                />
              </div>

              {/* Submit Button */}
              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-black px-3 py-3 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-black-2 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  {loading ? <PacmanLoader color="#fff" /> : 'Sign in'}
                </button>
              </div>
            </form>

            <p className="mt-10 text-center text-sm font-medium text-gray-500">
              Forgot Password?
              <NavLink to="/forgot-password" className="ml-2 font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
                Reset
              </NavLink>
            </p>

            {/* <p className="mt-5 text-center text-sm font-medium text-gray-500">
              Not Registered?
              <NavLink to="/createAccount" className="ml-2 font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
                Create Account
              </NavLink>
            </p> */}
          </div>
        </div>
      )}
    </>
  );
};

export default Login;
